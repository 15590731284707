import React, { useState } from "react";
import emailjs from 'emailjs-com';
import './ContactFormSection.css';

export default function ContactFormSection() {
  const [message, setMessage] = useState(null);

  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_mqbfvcq', 'template_gjtfuml', e.target, 'iDk8vDNKujOTWbRli')
      .then((result) => {
        console.log(result.text);
        setMessage("Email sent successfully!");
      }, (error) => {
        console.log(error.text);
        setMessage("An error occurred, please try again later.");
      });
  }

  return (
    <div className="container-fluid contact_form_section_parent_wrapper py-4">
      <div className="container contact_form_section_child_wrapper py-4">
        <div className="contact_form_section_content_wrapper py-3">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-12 col-md-10 col-lg-6">
              {message && (
                <div className={`alert ${message.includes("successfully") ? "alert-success" : "alert-danger"}`} role="alert">
                  {message}
                </div>
              )}
              <form id="form" className="d-flex flex-column" onSubmit={sendEmail}>
                <div className="form-group">
                  <h4 className="contact_caption">
                    Contact Us
                  </h4>
                </div>
                <div className="form-group mt-4">
                  <label htmlFor="to-name" className="font-weight-bold">
                    Full names <span className="font-weight-bold text-danger">*</span>
                  </label>
                  <input type="text" name="from_name" id="to-name" placeholder="eg Thabiso Letlhabile" className="form-control to-name mt-2" required />
                </div>
                <div className="form-group mt-3">
                  <label htmlFor="reply-email" className="font-weight-bold">
                    Email Address <span className="font-weight-bold text-danger">*</span>
                  </label>
                  <input type="email" name="from_email" id="from_email" placeholder="eg thabiso@gmail.com" className="form-control to-email mt-2" required />
                </div>
                <div className="form-group mt-3">
                  <label htmlFor="customer-message" className="font-weight-bold">
                    Your Message <span className="font-weight-bold text-danger">*</span>
                  </label>
                  <textarea name="customer_message" id="customer_message" className="form-control custom-textarea mt-2" cols="53" rows="3" required></textarea>
                </div>
                <div className="form-group mt-3">
                  <button type="submit" id="button" className="form-control text-white mt-2">Send Email</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
