import React, { useState } from "react";
import './AboutSection.css';
import aboutImg from './images/aboutImg.png';

export default function AboutSection() {
    const [isReadMoreVisible, setReadMoreVisible] = useState(false);

    const toggleReadMore = () => {
        setReadMoreVisible(!isReadMoreVisible);
    };

    return (
        <div className="container_fluid about_section_parent_wrapper py-3">
            <div className="container about_section_child_wrapper py-3">
                <br />
                <div className="row about_row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                        <img src={aboutImg} alt="" className="img-fluid mx-auto d-flexx" />
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 about_column d-flex justify-content-center flex-column">
                        <h5 className="about_heading">
                            About Our Director
                        </h5>
                        <p className="about_description mt-4">
                            Simon has worked hard since 2007 to build his name as a trusted attorney.
                            He has won high profile cases that he was working on
                            One of the cases was the one that made him go to Bloemfontein to the Supreme Court of Appeal for his client in 2018, which five judges were presiding over, and the case was ruled in his favor.
                        </p>
                        <p className="about_description mt-2">
                            <b>
                                Additionally he has skill in oral and trial advocacy
                            </b>
                        </p>
                        <div className={`toggle_read_more ${isReadMoreVisible ? '' : 'd-none'} d-lg-block`} id="toggle_read_more">
                            <p className="about_description">
                                He mentored a lot of lawyers who are now running their successful law firms now.
                                He is very much passionate about justice hence he does pro bono cases for the young and much deserving boys.
                            </p>
                            <p className="about_description">
                                To help them so that they can rebuild their lives.
                                Through his work, he was able to deliver excellent legal services to all his clients for over 16 years.
                                The purpose is to serve while fighting for justice.
                            </p>
                        </div>
                        <div className="about_btn_wrapper">
                            <button
                                type="button"
                                onClick={toggleReadMore}
                                className="btn btn-sm read_more_btn text-white"
                                id="read_more_btn"
                            >
                                {isReadMoreVisible ? 'Read Less' : 'Read More'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
