import React from "react";
import './FAQSection.css';
import { useState } from "react";
export default function FAQSection(){
    
    const FaqAccordion = () => {
        const faqData = [
          {
            question: 'How can I schedule a consultation with your firm?',
            answer: 'Scheduling a consultation is easy. You can either call our office during business hours or fill out the online contact form on our website. Our team will promptly get in touch to arrange a convenient time for your consultation.',
          },
          {
            question: 'Is there a fee for the initial consultation?',
            answer: 'We offer an initial consultation from 2000. Its an opportunity for us to understand your case and for you to explore how our services align with your needs. We believe in providing transparent information and guidance from the outset.',
          },
          {
            question: 'What information should I bring to the initial consultation?',
            answer: 'To make the most of your consultation, bring relevant documents, such as contracts, consultation fee, ID copy and proof of residential legal notices, or any paperwork related to your case. This allows us to better understand your situation and provide informed advice.',
          },
          {
            question: 'How long does a typical consultation last?',
            answer: 'The duration of a consultation can vary based on the complexity of your case. Generally, consultations last between 30 minutes to an hour. We strive to ensure ample time for discussion and address any questions you may have.',
          },
          {
            question: 'Can I schedule a consultation outside regular business hours?',
            answer: 'Yes, we understand the importance of flexibility. While our regular business hours are [insert hours], we can accommodate consultations outside these hours by appointment. Please contact our office to discuss and arrange a suitable time for your consultation.',
          },
        ];
      
        const [activeIndex, setActiveIndex] = useState(null);
      
        const handleAccordionClick = (index) => {
          setActiveIndex(activeIndex === index ? null : index);
        };
      
        return (
          <div className="accordion custom-accordion" id="faqAccordion">
            {faqData.map((faq, index) => (
              <div className="accordion-item mt-3" key={index} style={{border:"unset"}}>
                <h2 className="accordion-header mt-3 mt-4" id={`faqHeading${index}`}>
                  <button
                    className={`accordion-button text-white ${activeIndex === index ? 'collapsed' : ''}`}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#faqCollapse${index}`}
                    aria-expanded={activeIndex === index ? 'true' : 'false'}
                    aria-controls={`faqCollapse${index}`}
                    onClick={() => handleAccordionClick(index)}
                  >
                    {faq.question}
                  </button>
                </h2>
                <div
                  id={`faqCollapse${index}`}
                  className={`accordion-collapse collapse ${activeIndex === index ? 'show' : ''}`}
                  aria-labelledby={`faqHeading${index}`}
                  data-bs-parent="#faqAccordion"
                >
                  <div className="accordion-body">{faq.answer}</div>
                </div>
              </div>
            ))}
          </div>
        );
      };
    
    return(
        <div className="container-fluid faq_section_parent_wrapper py-4">
            <div className="faq_section_child_wrapper container py-4">
                <h1 className="faq_heading">Frequently Asked Questions</h1><br />
                <FaqAccordion />
            </div>
        </div>
    );
}