import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import './App.css';
import HomePage from './pages/HomePage/HomePage';
import AboutPage from './pages/AboutPage/AboutPage';
import ServicesPage from './pages/ServicesPage/ServicesPage';
import ContactPage from './pages/ContactPage/ContactPage';
import logoImg from './images/logo.png';
import React, { useState } from 'react';

export default function App() {
  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const closeMenu = () => {
    setMenuVisible(false);
  };

  return (
    <BrowserRouter>
      <div id='menuWrapper' className={`navigation_parent_wrapper py-4 ${menuVisible ? 'menu-open' : ''}`}>
        <div className="container navigation_child_wrapper d-flex align-items-center">
          <div className="logo_wrapper">
            <li className='text-white home_link'>
              <Link className='nav_link home_link' to='/' onClick={closeMenu}>
                <img style={{ height: 56 }} src={logoImg} alt="" className="img-fluid mx-auto" />
              </Link>
            </li>
          </div>
          <div className={`nav_list_items_wrapper container d-md-flex align-items-center justify-content-end ${menuVisible ? 'show' : 'hide'}`}>
            <li className="nav_link_item">
              <Link className='text-white active' to='/' onClick={closeMenu}><span>Home</span></Link>
            </li>
            <li className="nav_link_item mobile-d">
              <Link className='text-white' to='/aboutPage' onClick={closeMenu}><span>About</span></Link>
            </li>
            <li className="nav_link_item">
              <Link className='text-white' to='/servicesPage' onClick={closeMenu}><span>Services</span></Link>
            </li>
            <li className="nav_link_item contact_link">
              <Link className='text-white' to='/contactPage' onClick={closeMenu}><span>Contact</span></Link>
            </li>
          </div>
          <div id='menuBtn' onClick={toggleMenu} className="small_device_menu_wrapper container d-flex d-sm-flex d-md-none justify-content-end">
            <li className="nav_link_item">
              <i className="fa fa-2x fa-bars text-white"></i>
            </li>
          </div>
        </div>
      </div>

      <Routes>
        <Route path='*' element={<HomePage />} />
        <Route path='aboutPage' element={<AboutPage />} />
        <Route path='servicesPage' element={<ServicesPage />} />
        <Route path='contactPage' element={<ContactPage />} />
      </Routes>
    </BrowserRouter>
  );
}
