import React from "react";
import './HeroSection.css';
import heroImg from './images/main.png';
import { Link } from "react-router-dom";
export default function HeroSection(){
    return(
        <div className="hero_section_parent_wrapper bg-image">
            <div className="hero_section_child_wrapper hero_section_mask">
                <div className="container hero_section_content_wrapper">
                    <div className="row gx-4 gy-4 row_hero_section_row d-flex align-items-center">
                        <div className="col-12 col-sm-12 col-md-7">
                            <h1 className="hero_section_heading text-white">
                                16 YEARS OF &nbsp;
                                <span className="hero_section_span">FIGHTING</span>&nbsp;
                                FOR JUSTICE & THE RIGHTS OF OUR CLIENTS
                            </h1>
                            <p className="hero_section_description mt-4 text-white">
                                join +20 clients who trusted us with their cases & still trust us today
                            </p>
                            <div className="btn-wrapper mt-4">
                                <button className="text-white btn btn-sm contact_btn">
                                    <Link className="text-white" to="/contactPage">
                                        BOOK CONSULTATION
                                    </Link>
                                </button>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-5">
                            <div className="container">
                                <img src={heroImg} alt="" className="img-fluid mx-auto d-flex" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}