import React from "react";
import './AboutPage.css';
import AboutSection from "../../sections/AboutSection/AboutSection";
import ServicesSection from "../../sections/ServicesSection/ServicesSection";
import FAQSection from '../../sections/FAQSection/FAQSection';
import ContactSection from "../../sections/ContactSection/ContactSection";

export default function AboutPage(){
    return(
        <>
            <AboutSection/>
            <ServicesSection/>
            <FAQSection/>
            <ContactSection/>
        </>
    );
}