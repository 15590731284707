import React from "react";
import './ServicesPage.css';
import ServicesSection from "../../sections/ServicesSection/ServicesSection";
import { Link } from "react-router-dom";

import civil_law from './images/civil_law.jpg';
import commercial_law from './images/commercial_law.jpg';
import labour_law from './images/labour_law.png';
import criminal_law from './images/labour_law.png';
import real_estate from './images/real_estate_law.jpg';
import FAQSection from "../../sections/FAQSection/FAQSection";

export default function ServicesPage(){
    
    //servcices information data start here
    const serviceData = [
        {
          imgSrc: commercial_law,
          heading: 'COMMERCIAL LAW',
          description: 'We specialize in empowering businesses through tailored Commercial Law solutions. From meticulous contract drafting to swift dispute resolution, our expertise ensures your legal foundation is robust. Elevate your ventures with strategic guidance, proactive risk management, and a dedicated legal partner committed to your success'
        },
        {
            imgSrc: labour_law,
            heading: 'LABOUR LAW',
            description: 'We specialize in fortifying your workforce through proactive Labour Law solutions. From meticulous employment contract crafting to efficient dispute resolution, we prioritize fair practices, compliance, and fostering harmonious workplaces. Count on us as your dedicated legal partner committed to not only upholding your employees rights but also enhancing your business resilience and success'
        },
        {
            imgSrc: civil_law,
            heading: 'CIVIL LAW',
            description: 'We specialize in navigating the complexities of Civil Law to secure your personal and business interests. From meticulous contract drafting to empathetic handling of divorce proceedings, our expert legal guidance ensures your rights are protected. Trust us as your committed legal ally for comprehensive solutions tailored to your unique needs'
        },
        {
            imgSrc: criminal_law,
            heading: 'CRIMINAL LAW',
            description: 'We specialize in staunchly defending your rights with our expert Criminal Law services. From strategic defense in courtrooms to proactive legal counsel, we ensure your case is handled with precision and dedication. Trust us as your unwavering legal advocate committed to protecting your freedom and securing the best possible outcome.'
        },
        {
            imgSrc: real_estate,
            heading: 'ESTATE LAW',
            description: 'We specialize in providing compassionate and comprehensive Estates Law services. From meticulous will drafting to strategic estate planning, our expertise ensures your legacy is preserved. Trust us as your dedicated legal partner committed to navigating the complexities of probate, ensuring your wishes are honored, and securing a legacy for generations to come'
        },
    ];
    //services information data ended here


    //coding for the services component start here
    const ServiceCard = ({ imgSrc, heading, description }) => {
        return (
            <>
                <div className="col-12 col-sm-12 col-md-5 col-lg-4">
                    <img src={imgSrc} alt="" className="img-fluid mx-auto d-flex" />
                </div>
                <div className="col-12 col-sm-12 col-md-7 col-lg-8 d-flex flex-column justify-content-center">
                    <h5 className="service_heading">
                        {heading}
                    </h5>
                    <p className="service_description">
                        {description}
                    </p>
                </div>
            </>
        );
    };
    //coding for the services component ended here

    return(
        <>
            <div className="container py-4">
                <div className="row gy-4">
                    {serviceData.map((service, index) => (
                        <ServiceCard
                        key={index}
                        imgSrc={service.imgSrc}
                        heading={service.heading}
                        description={service.description}
                        />
                    ))}
                </div>
            </div>
            <FAQSection/>
        </>
    );
}