import React from "react";
import './ContactPage.css';
import ContactFormSection from "../../sections/ContactFormSection/ContactFormSection";

export default function ContactPage(){
    return(
        <>
            <ContactFormSection/>
        </>
    );
}