import React, { useState } from "react";
import './ServicesSection.css';
import { Link } from "react-router-dom";
import civilImg from './images/civil.png';
import criminalImg from './images/criminal.png';
import estateImg from './images/estate.png';
import financeImg from './images/finance.png';
import labourImg from './images/labour.png';

export default function ServicesSection() {
  const [displayedCards, setDisplayedCards] = useState(3);

  const serviceData = [
    {
      imgSrc: financeImg,
      heading: 'COMMERCIAL LAW',
      description: 'We specialize in tailored Commercial Law solutions. Our expertise ensures your legal foundation is robust. Elevate your ventures with strategic guidance & proactive risk management'
    },
    {
      imgSrc: labourImg,
      heading: 'LABOUR LAW',
      description: 'We specialize in comprehensive Labour Law solutions. From employment contracts to dispute resolution. We prioritize fair practices, compliance, and fostering harmonious workplaces.'
    },
    {
      imgSrc: civilImg,
      heading: 'CIVIL LAW',
      description: 'We specialize in navigating the complexities of Civil Law to secure your personal and business interests. From Divorce Handling & more'
    },
    {
      imgSrc: criminalImg,
      heading: 'CRIMINAL LAW',
      description: 'We specialize in staunchly defending your rights with our expert Criminal Law services. From strategic defense in courtrooms to proactive legal counsel.'
    },
    {
      imgSrc: estateImg,
      heading: 'ESTATE LAW',
      description: 'We specialize in providing compassionate and comprehensive Estates Law services. From meticulous will drafting to strategic estate planning, our expertise ensures your legacy is preserved'
    },
  ];

  const ServiceCard = ({ imgSrc, heading, description }) => {
    return (
      <div className="col-12 col-sm-12 col-md-6 col-lg-4">
        <div className="card services_card d-flex h-100 text-center">
          <div className="card-img-top mt-3">
            <img style={{ height: 50 }} src={imgSrc} alt="" className="img-fluid mx-auto" />
          </div>
          <div className="card-body service_card_body">
            <h6 className="services_card_heading mt-4">{heading}</h6>
            <p className="services_description">{description}</p>
            <p>
              <Link className="read_more_service" to="/servicesPage">Read More</Link>
            </p>
          </div>
        </div>
      </div>
    );
  };

  const showMoreCards = () => {
    setDisplayedCards((prevDisplayedCards) => (prevDisplayedCards === 3 ? serviceData.length : 3));
  };

  return (
    <div className="container_fluid services_section_parent_wrapper py-4">
      <div className="container services_section_child_wrapper py-2">
        <h2 className="services_heading mt-4">
          OUR SERVICES
        </h2>
        <br />
        <div className="row mt-2 gy-3 d-flex justify-content-center">
          {serviceData.slice(0, displayedCards).map((service, index) => (
            <ServiceCard
              key={index}
              imgSrc={service.imgSrc}
              heading={service.heading}
              description={service.description}
            />
          ))}
        </div>
        {serviceData.length > 3 && (
          <div className="read_more_btn_wrapper text-center mt-4">
            <button
              type="button"
              onClick={showMoreCards}
              className="btn btn-sm read_more_btn text-white"
            >
              {displayedCards === 3 ? 'Show More' : 'Show Less'}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
