import react from "react";
import './HomePage.css';
import HeroSection from "../../sections/HeroSection/HeroSection";
import ServicesSection from '../../sections/ServicesSection/ServicesSection';
import AboutSection from '../../sections/AboutSection/AboutSection';
import ContactSection from '../../sections/ContactSection/ContactSection';
import FAQSection from "../../sections/FAQSection/FAQSection";
export default function HomePage(){
    return(
        <>
            <HeroSection/>
            <ServicesSection/>
            <AboutSection/>
            <FAQSection/>
            <ContactSection/>
        </>
    );
}