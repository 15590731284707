import React from "react";
import './ContactSection.css';

export default function ContactSection(){
    return(
        <div className="container_fluid contact_section_parent_wrapper py-4 text-white">
            <div className="container contact_section_child_wrapper py-4">
                <div className="row py-4 gy-4">
                    <div className="col-12 col-sm-12 col-md-4">
                        <h5 className="contact_heading">
                            Company Information
                        </h5>
                        <li className="mt-3">
                            Company name:
                            SN Molele Inc. 
                        </li>
                        <li className="mt-2">
                            Director/ Attorney: 
                            Nare Simon Molele 
                        </li>
                        <li className="mt-2">
                            Company reg. No.: 
                            2007/035425/21 
                        </li>
                        <li className="mt-2">
                            Qualification:
                            Bproc UNISA
                        </li>
                    </div>
                    <div className="col-12 col-sm-12 col-md-4">
                        <h5 className="contact_heading">
                            Contact Information
                        </h5>
                        <li className="mt-3">
                            Office number: 
                            0728079127
                        </li>
                        <li>
                            Email Address : &nbsp;<br />
                            info@snmoleleattorneys.com <br />
                        </li>
                    </div>
                    <div className="col-12 col-sm-12 col-md-4">
                        <h5 className="contact_heading">
                            Address Information
                        </h5>
                        <li className="mt-3">
                            252 castaletto street
                            Elandspoort
                            Pretoria west
                        </li>
                        <h5 className="contact_heading mt-4">
                            Working Hours
                        </h5>
                        <li>
                            08:00-16:00 weekdays
                        </li>
                    </div>
                </div>
            </div>
        </div>
    );
}